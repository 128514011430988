import { render, staticRenderFns } from "./SignUpPage.vue?vue&type=template&id=653a3dd8&scoped=true"
import script from "./SignUpPage.vue?vue&type=script&lang=js"
export * from "./SignUpPage.vue?vue&type=script&lang=js"
import style0 from "@/concord-hospitality/partials/icons-font/css/all.css?vue&type=style&index=0&id=653a3dd8&prod&scoped=true&lang=css&external"
import style1 from "@/concord-hospitality/partials/min.css?vue&type=style&index=1&id=653a3dd8&prod&scoped=true&lang=css&external"
import style2 from "@/concord-hospitality/partials/style.css?vue&type=style&index=2&id=653a3dd8&prod&scoped=true&lang=css&external"
import style3 from "./SignUpPage.vue?vue&type=style&index=3&id=653a3dd8&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653a3dd8",
  null
  
)

export default component.exports