<template>
    <div class="container-fuild" v-loading.fullscreen.lock="loading">
        <div class="wrapper">
            <div class="heading">
                <div class="main-heading d-flex justify-content-center align-item-center flex-wrap">
                    <h1></h1>
                </div>
            </div>
        </div>
        <form class="container my-container">
            <div class="row mb-2">
                <div class="col-lg-12">
                    <h2 class="form-heading text-center mt-3">Sign Up</h2>
                    <p class="form-text text-justify">Hello Concord Team! Welcome to your training platform. Please select which location you work at and fill out the required profile information. Once your location is selected, the system will automatically assign you the required courses. You will also have the option to add courses that are not required, but are available to you through the Concord training platform. If you have any questions, please email us at <a href="mailto:support@homeoftraining.com">support@homeoftraining.com</a>. Thank you!</p>
                </div>
            </div>
            <div class="row form-row">
                <div class="form-group col-md-6">
                    <label>Please select your state<span>*</span></label>
                    <select class="dropdown custom-select" v-model="selectedLocation" :class="userDetailsError.location != '' ? 'is-invalid' : ''" @change="resetStateOnLocationOrJobTitleChange">
                        <option selected disabled value="0">Select a state</option>
                        <option v-for="location in locations" :value="location.id" v-if="location.id!=companyId">{{ location.name }}</option>
                    </select>
                    <div class="invalid-feedback">{{ userDetailsError.location }}</div>
                </div>
                <div class="form-group col-md-6">
                    <label>Job Title<span>*</span></label>
                    <select class="dropdown custom-select" v-model="selectedJobId" :class="userDetailsError.jobTitle != '' ? 'is-invalid' : ''" @change="resetStateOnLocationOrJobTitleChange">
                        <option selected disabled value="0">Select a job title</option>
                        <option v-for="jobTitle in jobTitles" :value="jobTitle.id">{{ jobTitle.name }}</option>
                    </select>
                    <div class="invalid-feedback">{{ userDetailsError.jobTitle }}</div>
                </div>
                <div class="form-group  col-md-6  col-12">
                    <label>First Name<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.firstName != '' ? 'is-invalid' : ''" type="text" placeholder="First Name" v-model="userDetails.firstName">
                    <div class="invalid-feedback">{{ userDetailsError.firstName }}</div>
                </div>
                <div class="form-group  col-md-6 col-12">
                    <label>Last Name<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.lastName != '' ? 'is-invalid' : ''" type="text" placeholder="Last Name" v-model="userDetails.lastName">
                    <div class="invalid-feedback">{{ userDetailsError.lastName }}</div>
                </div>
                <div class="form-group  col-md-6  col-12">
                    <label>Email<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.email != '' ? 'is-invalid' : ''" type="text" placeholder="Email" v-model="userDetails.email">
                    <div class="invalid-feedback">{{ userDetailsError.email }}</div>
                </div>
                <div class="form-group  col-md-6  col-12">
                    <label>Phone Number<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.phoneNumber != '' ? 'is-invalid' : ''" type="text" placeholder="Phone Number" @input="acceptNumber" v-model="userDetails.phoneNumber">
                    <div class="invalid-feedback">{{ userDetailsError.phoneNumber }}</div>
                </div>
                <div class="form-group  col-md-6 col-12">
                    <label>Address<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.address != '' ? 'is-invalid' : ''" type="text" placeholder="Address" v-model="userDetails.address">
                    <div class="invalid-feedback">{{ userDetailsError.address }}</div>
                </div>
                <div class="form-group col-md-6 col-12">
                    <label>City<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.city != '' ? 'is-invalid' : ''" type="text" placeholder="City" v-model="userDetails.city">
                    <div class="invalid-feedback">{{ userDetailsError.city }}</div>
                </div>
                <div class="form-group  col-md-6 col-12">
                    <label>State<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.state != '' ? 'is-invalid' : ''" type="text" placeholder="State" v-model="userDetails.state">
                    <div class="invalid-feedback">{{ userDetailsError.state }}</div>
                </div>
                <div class="form-group  col-md-6  col-12">
                    <label>Zip Code<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.zipcode != '' ? 'is-invalid' : ''" type="text" placeholder="Zip Code" v-model="userDetails.zipcode">
                    <div class="invalid-feedback">{{ userDetailsError.zipcode }}</div>
                </div>
                <div class="form-group  col-md-6  col-12">
                    <label>Create Password<span>*</span></label>
                    <input class="form-control" :class="userDetailsError.password != '' ? 'is-invalid' : ''" type="password" placeholder="Create Password" v-model="userDetails.password">
                    <div class="invalid-feedback">{{ userDetailsError.password }}</div>
                </div>

                <div class="form-group col-12">
                    <h2 class="form-sub-heading text-start">Below is a list of the courses that Concord requires you to complete.</h2>
                    <input type="text" class="form-control" disabled="disabled" :value="getRequiredCoursesAndCourseFolders" placeholder="Required Courses" style="background-color: #fff">
                </div>
            </div>
            <div class="row form-row">

            </div>
            <div class="row mt-2">
                <div class="col-lg-12">
                    <div class="submit-btn d-flex align-item-center pb-5 float-left">
                        <div class="button">
                            <a href="javascript:void(0)" class="calculate-btn text-decoration-none" @click="sendLead" v-if="showCalculateButton == true && showReEstimateButton == false">Calculate <i class="fa-solid fa-arrow-right"></i></a>
                            <a href="javascript:void(0)" class="calculate-btn text-decoration-none" @click="sendLead" v-if="showReEstimateButton == true">Re-estimate <i class="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-3" v-if="showCalculateButton == false" ref="scrollToMe">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 promocode-contain">
                    <div class="row">
                        <div class="col-lg-12 edit-bacground">
                            <div class="promotion-code-container" v-if="promocode.discountedPrice != 0">
                                <div class="promo1">
                                    <p>Total Price</p>
                                </div>
                                <div class="promo1">
                                    <p class="text-right">{{ formatPrice(promocode.originalPrice) }}</p>
                                </div>
                            </div>
                            <div class="promotion-code-container" v-if="promocode.discountedPrice != 0">
                                <div class="promo1">
                                    <p>Discount</p>
                                </div>
                                <div class="promo1">
                                    <p class="text-right">-{{ formatPrice(promocode.discountedPrice) }}</p>
                                </div>
                            </div>
                            <div class="promotion-code-container">
                                <div class="promo1">
                                    <p>Amount Payable</p>
                                </div>
                                <div class="promo1">
                                    <p class="text-right">{{ formatPrice(totalPrice) }}</p>
                                </div>
                            </div>
                            <div class="promotion-code-container">
                                <div class="promo1">
                                    <input class="form-control promotion-code-input" type="text" placeholder="Promotional Code" v-model="promocode.value" :class="this.promocode.error ? 'is-invalid' : ''">
                                    <div class="invalid-feedback">{{ promocode.error }}</div>
                                </div>
                                <div class="promo1 edit-btn">
                                    <a href="javascript:void(0)" @click="applyPromoCode" class="apply-btn text-decoration-none">APPLY</a>
                                </div>
                                <small class="mt-2">To receive a 15% discount, type in the following promotional code: concord</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12" v-if="showReEstimateButton == true">
                    <div class="submit-btn d-flex align-item-center pb-5 ">
                        <div class="button btn-edit">
                            <a href="javascript:void(0)" @click="showPaymentGateway">Create Account <i class="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <modal :show.sync="showPaymentModal">
            <h4 slot="header" style="color:#444C57" class="title title-up text-center">Payment</h4>
            <credit-card :amount="totalPrice" :address="userDetails.address" :city="userDetails.city" :state="userDetails.state" :zip="userDetails.zipcode" :enablePaymentButton="enablePaymentButton" v-on:payClicked="signUp"/>
        </modal>
    </div>
</template>
<script>
import dotenv from "dotenv";

dotenv.config();

import {DatePicker, Select, Option} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CreditCard from "./CreditCard.vue";

export default {
    name: 'concord-hospitality',
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [DatePicker.name]: DatePicker,
        CreditCard
    },
    data() {
        return {
            loading: false,
            companyId: 0,
            locations: [],
            jobTitles: [
                {
                    id: 1,
                    name: 'Employee – Serves Food',
                },
                {
                    id: 2,
                    name: 'Employee – Serves Food & Alcohol',
                },
                {
                    id: 5,
                    name: 'Employee – Serves Alcohol',
                },
                {
                    id: 3,
                    name: 'Manager – Serves Food',
                },
                {
                    id: 4,
                    name: 'Manager – Serves Food & Alcohol',
                },
                {
                    id: 6,
                    name: 'Manager Serves Food - Exam Only',
                },
            ],
            locationCoursesAndCourseFolders: [],
            selectedJobId: 0,
            selectedJobTitle: 'employee',
            selectedLocation: 0,
            isAlcoholRole: false,
            selectedCourseAndCourseFolders: [],
            showCalculateButton: true,
            showReEstimateButton: false,
            enablePaymentButton: false,
            showPaymentModal: false,
            promocode: {
                value: '',
                error: '',
                discount: 0,
                discountedPrice: 0,
                originalPrice: 0,
            },
            totalPrice: 0,
            userDetails: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                address: '',
                city: '',
                state: '',
                zipcode: '',
                password: '',
                doYouHaveAValidFoodHandlerCertificate: 'No',
                foodHandlerCertificateExpiryDate: '',
                foodHandlerCertificateImage: null,
                doYouHaveAValidAlcoholCertificate: 'No',
                alcoholCertificateExpiryDate: '',
                alcoholCertificateImage: null,
            },
            userDetailsError: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                address: '',
                city: '',
                state: '',
                zipcode: '',
                location: '',
                jobTitle: '',
                password: '',
                foodHandlerCertificateExpiryDate: '',
                foodHandlerCertificateImage: '',
                alcoholCertificateExpiryDate: '',
                alcoholCertificateImage: '',
            },
            requiredCoursesName: '',
        };
    },
    mounted() {
        this.companyId = process.env.VUE_APP_CONCORD_HOSPITALITY_COMPANY_ID;
        this.fetchChildLocations();
    },
    methods: {
        formatPrice(value) {
            return (
                "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            );
        },
        fetchChildLocations() {
            this.loading = true;
            this.$http.get("location/fetch-child-location", {
                params: {
                    companyId: this.companyId,
                }
            }).then(response => {
                this.locations = response.data;
                this.loading = false;
                this.fetchSignUpCourseAndCourseFolders();
            }).catch(error => {
                this.loading = false;
                console.log(error);
            });
        },
        fetchSignUpCourseAndCourseFolders() {
            this.loading = true;
            let locations = [];
            this.locations.forEach(location => {
                locations.push(location.id);
            });
            this.$http.get("location/fetch-child-location-course-course-folder", {
                params: {
                    locations: locations,
                }
            }).then(response => {
                this.loading = false;
                this.locationCoursesAndCourseFolders = response.data.data;
            })
        },
        acceptNumber() {
            var x = this.userDetails.phoneNumber.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.userDetails.phoneNumber = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        sendLead() {
            this.loading = true;
            if (this.promocode.value == '') {
                this.promocode.error = '';
            }

            if (this.showCalculateButton == true) {
                this.showCalculateButton = false;
            }

            if (this.showReEstimateButton == false) {
                this.showReEstimateButton = true;
            }

            // Reset the validations errors
            this.userDetailsError = {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                address: '',
                city: '',
                state: '',
                zipcode: '',
                location: '',
                jobTitle: '',
                password: '',
                doYouHaveAValidFoodHandlerCertificate: '',
                foodHandlerCertificateImage: '',
                foodHandlerCertificateExpiryDate: '',
                alcoholCertificateImage: '',
                alcoholCertificateExpiryDate: '',
            };

            let data = this.userDetails;
            data.location = this.selectedLocation;
            data.jobTitle = this.selectedJobTitle;
            data.companyId = this.companyId;
            data.promocode = this.promocode.value;

            let additionalCoursesAndCourseFolders = [];
            let requiredCourseAndCourseFolders = [];

            if (this.selectedCourseAndCourseFolders.length > 0) {
                this.selectedCourseAndCourseFolders.forEach(selectedCourseAndCourseFolders => {
                    this.getAdditionalCoursesAndCourseFolders.forEach((getAdditionalCoursesAndCourseFolders, index) => {
                        if (index == selectedCourseAndCourseFolders) {
                            additionalCoursesAndCourseFolders.push(getAdditionalCoursesAndCourseFolders);
                        }
                    })
                })
            }

            data.additionalCourseAndCourseFolders = additionalCoursesAndCourseFolders;

            if (this.selectedLocation != 0 && this.selectedJobTitle != '') {
                this.locationCoursesAndCourseFolders[this.selectedLocation][this.selectedJobTitle]['requiredCourseFolder'].forEach(requiredCourse => {
                    requiredCourseAndCourseFolders.push(requiredCourse);
                });
                this.locationCoursesAndCourseFolders[this.selectedLocation][this.selectedJobTitle]['requiredCourse'].forEach(requiredCourse => {
                    requiredCourseAndCourseFolders.push(requiredCourse);
                });
            }

            data.requiredCourseAndCourseFolders = requiredCourseAndCourseFolders;

            let formData = new FormData();
            formData.append('firstName', this.userDetails.firstName);
            formData.append('lastName', this.userDetails.lastName);
            formData.append('email', this.userDetails.email);
            formData.append('phoneNumber', this.userDetails.phoneNumber);
            formData.append('address', this.userDetails.address);
            formData.append('city', this.userDetails.city);
            formData.append('state', this.userDetails.state);
            formData.append('zipcode', this.userDetails.zipcode);
            formData.append('password', this.userDetails.password);
            // formData.append('doYouHaveAValidFoodHandlerCertificate', this.userDetails.doYouHaveAValidFoodHandlerCertificate);
            // formData.append('foodHandlerCertificateExpiryDate', this.userDetails.foodHandlerCertificateExpiryDate);
            // formData.append('foodHandlerCertificateImage', this.userDetails.foodHandlerCertificateImage);

            // formData.append('doYouHaveAValidAlcoholCertificate', this.userDetails.doYouHaveAValidAlcoholCertificate);
            // formData.append('alcoholCertificateExpiryDate', this.userDetails.alcoholCertificateExpiryDate);
            // formData.append('alcoholCertificateImage', this.userDetails.alcoholCertificateImage);

            formData.append('location', this.selectedLocation);
            formData.append('jobTitle', this.selectedJobTitle);
            formData.append('companyId', this.companyId);
            formData.append('promocode', this.promocode.value);
            formData.append('selectedJobId', this.selectedJobId);

            for (let i = 0; i < requiredCourseAndCourseFolders.length; i++) {
                formData.append(`requiredCourseAndCourseFolders[${i}][id]`, requiredCourseAndCourseFolders[i]['id']);
                formData.append(`requiredCourseAndCourseFolders[${i}][location_id]`, requiredCourseAndCourseFolders[i]['location_id']);
                formData.append(`requiredCourseAndCourseFolders[${i}][is_manager]`, requiredCourseAndCourseFolders[i]['is_manager']);
                formData.append(`requiredCourseAndCourseFolders[${i}][is_employee]`, requiredCourseAndCourseFolders[i]['is_employee']);
                formData.append(`requiredCourseAndCourseFolders[${i}][course_id]`, requiredCourseAndCourseFolders[i]['course_id']);
                formData.append(`requiredCourseAndCourseFolders[${i}][folder_id]`, requiredCourseAndCourseFolders[i]['folder_id']);
                formData.append(`requiredCourseAndCourseFolders[${i}][created_at]`, requiredCourseAndCourseFolders[i]['created_at']);
                formData.append(`requiredCourseAndCourseFolders[${i}][updated_at]`, requiredCourseAndCourseFolders[i]['updated_at']);
                formData.append(`requiredCourseAndCourseFolders[${i}][deleted_at]`, requiredCourseAndCourseFolders[i]['deleted_at']);
                formData.append(`requiredCourseAndCourseFolders[${i}][is_required]`, requiredCourseAndCourseFolders[i]['is_required']);
                formData.append(`requiredCourseAndCourseFolders[${i}][name]`, requiredCourseAndCourseFolders[i]['name']);
                formData.append(`requiredCourseAndCourseFolders[${i}][cost]`, requiredCourseAndCourseFolders[i]['cost']);
            }

            for (let i = 0; i < additionalCoursesAndCourseFolders.length; i++) {
                formData.append(`additionalCourseAndCourseFolders[${i}][id]`, additionalCoursesAndCourseFolders[i]['id']);
                formData.append(`additionalCourseAndCourseFolders[${i}][location_id]`, additionalCoursesAndCourseFolders[i]['location_id']);
                formData.append(`additionalCourseAndCourseFolders[${i}][is_manager]`, additionalCoursesAndCourseFolders[i]['is_manager']);
                formData.append(`additionalCourseAndCourseFolders[${i}][is_employee]`, additionalCoursesAndCourseFolders[i]['is_employee']);
                formData.append(`additionalCourseAndCourseFolders[${i}][course_id]`, additionalCoursesAndCourseFolders[i]['course_id']);
                formData.append(`additionalCourseAndCourseFolders[${i}][folder_id]`, additionalCoursesAndCourseFolders[i]['folder_id']);
                formData.append(`additionalCourseAndCourseFolders[${i}][created_at]`, additionalCoursesAndCourseFolders[i]['created_at']);
                formData.append(`additionalCourseAndCourseFolders[${i}][updated_at]`, additionalCoursesAndCourseFolders[i]['updated_at']);
                formData.append(`additionalCourseAndCourseFolders[${i}][deleted_at]`, additionalCoursesAndCourseFolders[i]['deleted_at']);
                formData.append(`additionalCourseAndCourseFolders[${i}][is_required]`, additionalCoursesAndCourseFolders[i]['is_required']);
                formData.append(`additionalCourseAndCourseFolders[${i}][name]`, additionalCoursesAndCourseFolders[i]['name']);
                formData.append(`additionalCourseAndCourseFolders[${i}][cost]`, additionalCoursesAndCourseFolders[i]['cost']);
            }

            this.$http.post("location/location-user-lead-email", formData).then(response => {
                if (response.data.promocodeError !== '') {
                    this.promocode.error = response.data.promocodeError;
                    Swal.fire({
                        title: "Error!",
                        html: response.data.promocodeError,
                        icon: "error"
                    }).then(() => {
                        setTimeout(() => {
                            this.promocode.error = '';
                            this.promocode.value = '';
                        }, 500);
                    });
                }
                this.promocode.discountedPrice = response.data.discountedPrice;
                this.promocode.originalPrice = response.data.originalPrice;
                this.totalPrice = response.data.totalPrice;
                this.requiredCoursesName = response.data.requiredCoursesName;
                this.scrollToPaymentDetails();
            }).catch(error => {
                let otherError = true;
                Swal.fire({
                    title: "Error!",
                    html: "Please fix the validation errors",
                    icon: "error"
                });
                if (error.response.data.firstName !== undefined) {
                    this.userDetailsError.firstName = error.response.data.firstName.join(", ");
                    otherError = false;
                }
                if (error.response.data.lastName !== undefined) {
                    this.userDetailsError.lastName = error.response.data.lastName.join(", ");
                    otherError = false;
                }
                if (error.response.data.email !== undefined) {
                    this.userDetailsError.email = error.response.data.email.join(", ");
                    otherError = false;
                }
                if (error.response.data.phoneNumber !== undefined) {
                    this.userDetailsError.phoneNumber = error.response.data.phoneNumber.join(", ");
                    otherError = false;
                }
                if (error.response.data.address !== undefined) {
                    this.userDetailsError.address = error.response.data.address.join(", ");
                    otherError = false;
                }
                if (error.response.data.city !== undefined) {
                    this.userDetailsError.city = error.response.data.city.join(", ");
                    otherError = false;
                }
                if (error.response.data.state !== undefined) {
                    this.userDetailsError.state = error.response.data.state.join(", ");
                    otherError = false;
                }
                if (error.response.data.zipcode !== undefined) {
                    this.userDetailsError.zipcode = error.response.data.zipcode.join(", ");
                    otherError = false;
                }
                if (error.response.data.location !== undefined) {
                    this.userDetailsError.location = error.response.data.location.join(", ");
                    otherError = false;
                }
                if (error.response.data.jobTitle !== undefined) {
                    this.userDetailsError.jobTitle = error.response.data.jobTitle.join(", ");
                    otherError = false;
                }
                if (error.response.data.password !== undefined) {
                    this.userDetailsError.password = error.response.data.password.join(", ");
                    otherError = false;
                }
                if (error.response.data.foodHandlerCertificateImage !== undefined) {
                    this.userDetailsError.foodHandlerCertificateImage = error.response.data.foodHandlerCertificateImage.join(", ");
                    otherError = false;
                }
                if (error.response.data.foodHandlerCertificateExpiryDate !== undefined) {
                    this.userDetailsError.foodHandlerCertificateExpiryDate = error.response.data.foodHandlerCertificateExpiryDate.join(", ");
                    otherError = false;
                }
                if (error.response.data.alcoholCertificateImage !== undefined) {
                    this.userDetailsError.alcoholCertificateImage = error.response.data.alcoholCertificateImage.join(", ");
                    otherError = false;
                }
                if (error.response.data.alcoholCertificateExpiryDate !== undefined) {
                    this.userDetailsError.alcoholCertificateExpiryDate = error.response.data.alcoholCertificateExpiryDate.join(", ");
                    otherError = false;
                }
                if (otherError === true) {
                    Swal.fire({
                        title: "Error!",
                        html: error.response.data.message,
                        icon: "error"
                    });
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        showPaymentGateway() {
            this.sendLead();
            this.showPaymentModal = true;
            this.enablePaymentButton = false;
        },
        signUp(cardData) {
            this.loading = true;
            let data = this.userDetails;
            let payment = {
                payment_type: "one-time",
                cardholder_street_address: cardData.address + ", " + cardData.city + ", " + cardData.state,
                cardholder_zip: cardData.zip,
                token: cardData.token,
                transaction_amount: this.totalPrice.toFixed(2),
            };
            data.payment = payment;
            data.selectedJobId = this.selectedJobId;
            this.$http.post("location/location-user-signup", data).then(response => {
                this.showPaymentModal = false;
                Swal.fire({
                    title: "Success!",
                    text: `Account created successfully.`,
                    icon: "success"
                });
                this.$router.push("/login");
            }).catch(error => {
                this.enablePaymentButton = false;
                Swal.fire({
                    title: "Error!",
                    html: "Something went wrong",
                    icon: "error"
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        resetStateOnLocationOrJobTitleChange() {
            this.requiredCoursesName = '';
            this.selectedCourseAndCourseFolders = [];
        },
        applyPromoCode() {
            this.promocode.error = '';
            if (this.promocode.value == '') {
                this.promocode.error = "The promocode field is required.";
                return false;
            }
            this.sendLead();
        },
        handleFileUpload(event, type) {
            if(type == 'foodHandler') {
                this.userDetails.foodHandlerCertificateImage = event.target.files[0];
            }

            if(type == 'alcohol') {
                this.userDetails.alcoholCertificateImage = event.target.files[0];
            }
        },
        scrollToPaymentDetails() {
            const el = this.$refs.scrollToMe;
            if (el) {
                el.scrollIntoView({behavior: 'smooth'});
            }
        }

    },
    computed: {
        getRequiredCoursesAndCourseFolders() {
            let requiredCourseAndCourseFolders = [];

            if (this.selectedLocation != 0 && this.selectedJobTitle != '') {
                this.locationCoursesAndCourseFolders[this.selectedLocation][this.selectedJobTitle]['requiredCourseFolder'].forEach(requiredCourse => {
                    requiredCourseAndCourseFolders.push(requiredCourse.name);
                });
                this.locationCoursesAndCourseFolders[this.selectedLocation][this.selectedJobTitle]['requiredCourse'].forEach(requiredCourse => {
                    requiredCourseAndCourseFolders.push(requiredCourse.name);
                });
            }

            if (this.requiredCoursesName == '') {
                return requiredCourseAndCourseFolders.join(", ");
            }
            return this.requiredCoursesName;
        },
        getAdditionalCoursesAndCourseFolders() {
            let additionalCoursesAndCourseFolders = [];
            if (this.selectedLocation != 0 && this.selectedJobTitle != '') {
                this.locationCoursesAndCourseFolders[this.selectedLocation][this.selectedJobTitle]['additionalCourseFolder'].forEach(requiredCourse => {
                    additionalCoursesAndCourseFolders.push(requiredCourse);
                });
                this.locationCoursesAndCourseFolders[this.selectedLocation][this.selectedJobTitle]['additionalCourse'].forEach(requiredCourse => {
                    additionalCoursesAndCourseFolders.push(requiredCourse);
                });
            }
            return additionalCoursesAndCourseFolders;
        }
    },
    watch: {
        selectedJobId: function () {
            this.isAlcoholRole = false;
            this.selectedJobTitle = '';

            if (this.selectedJobId == 1 || this.selectedJobId == 2 || this.selectedJobId == 5) {
                this.selectedJobTitle = 'employee';
            } else if (this.selectedJobId == 3 || this.selectedJobId == 4 || this.selectedJobId == 6) {
                this.selectedJobTitle = 'manager';
            }

            if (this.selectedJobId == 2 || this.selectedJobId == 4 || this.selectedJobId == 5) {
                this.isAlcoholRole = true;
            }
        }
    }
};
</script>
<style scoped src="@/concord-hospitality/partials/icons-font/css/all.css"></style>
<style scoped src="@/concord-hospitality/partials/min.css"></style>
<style scoped src="@/concord-hospitality/partials/style.css"></style>
<style>
label span {
    color: red !important;
}

div.invalid-feedback {
    font-weight: bold;
}

.el-select-dropdown__item {
    padding: 0 20px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #606266;
    height: 34px;
    line-height: 34px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}


div.custom-date-picker {
    background-color: #fff;
}

.custom-date-picker .is-invalid input,
.custom-date-picker .is-invalid .input__inner:focus {
    border: 1px solid #ffffff !important;
}

.custom-date-picker .is-invalid input[type="text"] {
    border: 1px solid #ffffff !important;
}

div.custom-date-picker .is-invalid {
    border: 1px solid #ff0000;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-radius: 0.25rem;
}

.calculate-btn {
    color: var(--heading) !important;
    background-color: #1f3853 !important;
    color: #FFF !important;
    padding: 8px 20px !important;
    border-radius: 5px !important;
}

</style>
